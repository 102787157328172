import React from 'react';

const CERTEIFICATE = {
  場勘評估: {
    zh: '場勘評估',
    en: 'Site assessment',
    ja: '現場評価',
  },
  場勘評估內文: {
    zh: '丈量場地、紀錄需求',
    en: 'Product manufacturing',
    ja: '現場の測定、記録要求',
  },
  執行建議: {
    zh: '執行建議',
    en: 'Implementation recommendation',
    ja: '実施提案',
  },
  執行建議內文: {
    zh: '依照現場狀態，建議使用支架型式、規模',
    en: 'Use the site conditions to suggest the applied support type and scales',
    ja: '現場の状況に応じて提案します、使用する架台のスタイル',
  },
  圖稿設計: {
    zh: '圖稿設計',
    en: 'Drawing design',
    ja: '製品設計',
  },
  圖稿設計內文: {
    zh: '客製化設計稿紙',
    en: 'Drawing of customized design',
    ja: 'カスタマイズされた設計図',
  },
  產品製造: {
    zh: '產品製造',
    en: 'Product manufacturing',
    ja: '製品製造',
  },
  產品製造內文: {
    zh: '準確縝密製作',
    en: 'Precision manufacturing',
    ja: '正確かつ綿密な製作',
  },
  出貨: {
    zh: '出貨',
    en: 'Shipping',
    ja: '出荷',
  },
  出貨內文: {
    zh: '分配包裹出貨',
    en: 'Pack separately for shipping',
    ja: '分割梱包して出荷',
  },
  品管: {
    zh: '品管',
    en: 'Quality control',
    ja: '品質管理',
  },
  製程: {
    zh: '製程',
    en: 'Production process',
    ja: '製造工程',
  },
  檢驗項目: {
    zh: '檢驗項目',
    en: 'Inspection items',
    ja: '検査項目',
  },

  // 依序為aa, ab, ac, ba, bb, bc, ca, ... 3*n
  品管流程內容aa: {
    zh: 'IQC',
    en: 'IQC',
    ja: 'IQC',
  },
  品管流程內容ab: {
    zh: '採購',
    en: 'Purchasing',
    ja: '購入',
  },
  品管流程內容ac: {
    zh: ['板材 - 規格', '鋼捲 - 規格'],
    en: ['Plate material - Specifications', 'Steel rolls - Specifications'],
    ja: ['板材 - 規格', '鋼材コイル - 規格'],
  },
  品管流程內容ba: {
    zh: 'IPQC',
    en: 'IPQC',
    ja: 'IPQC',
  },
  品管流程內容bb: {
    zh: '製造',
    en: 'Manufacturing',
    ja: '製造',
  },
  品管流程內容bc: {
    zh: ['成型 - 規格', '切割 - 規格'],
    en: ['Forming - Specifications', 'Cutting - Specifications'],
    ja: ['成形 - 規格', '切断 - 規格'],
  },
  品管流程內容ca: {
    zh: 'IPQC',
    en: 'IPQC',
    ja: 'IPQC',
  },
  品管流程內容cb: {
    zh: '加工',
    en: 'Processing',
    ja: '加工',
  },
  品管流程內容cc: {
    zh: ['沖孔 - 直徑 / 孔位', '折床 - 規格 / 角度'],
    en: ['Punching holes - Diameter / hole position', 'Bending platform - Specifications / angle'],
    ja: ['プレス - 直径 / 穴位置', '曲げ - 規格 / 角度'],
  },
  品管流程內容da: {
    zh: 'FQC',
    en: 'FQC',
    ja: 'FQC',
  },
  品管流程內容db: {
    zh: '組立',
    en: 'Assembly',
    ja: '組立',
  },
  品管流程內容dc: {
    zh: ['立柱 - 組裝方式', '支架 - 組裝方式 / 方向'],
    en: ['Setting the columns - Assembly method', 'Support - Assembly method / direction'],
    ja: ['立柱 - 組立方法', '架台 - 組立方法 / 方向'],
  },
  品管流程內容ea: {
    zh: 'QQC',
    en: 'QQC',
    ja: 'QQC',
  },
  品管流程內容eb: {
    zh: '入庫',
    en: 'Warehousing',
    ja: '入庫',
  },
  品管流程內容ec: {
    zh: ['半成品 - 規格 / 數量 / 外觀', '成品 - 規格 / 數量 / 外觀'],
    en: [
      'Semi-finished product - Specifications / quantity / exterior appearance',
      'Finished product - Specifications / quantity / exterior appearance',
    ],
    ja: ['半製品 - 規格 / 数量 / 外観', '製品 - 規格/ 数量 / 外観'],
  },
  品管流程內容fa: {
    zh: '',
    en: '',
    ja: '',
  },
  品管流程內容fb: {
    zh: '出貨',
    en: 'Shipping',
    ja: '出荷',
  },
  品管流程內容fc: {
    zh: ['包裝 - 數量 / 外觀 / 標示', '裝箱 - 數量/ 外觀/ 標示'],
    en: ['Packaging - Quantity / exterior appearance / labeling', 'Boxing - Quantity / exterior appearance / labeling'],
    ja: ['包裝 - 数量 / 外観 / 表示', '梱包 - 数量/ 外観/ 表示'],
  },
};

const langPack = {
  // Homepage **********
  頁面標題: {
    zh: '景欣股份有限公司 | 鎂鋁鋅太陽能支架 | 台灣',
    en: 'JING-XIN LTD. | Solar Mounting Bracket | Taiwan',
    ja: 'チンシン株式会社 | 太陽光架台 | 台湾',
  },
  // Nav
  太陽能支架: {
    zh: '太陽能支架',
    en: 'Mounting Bracket',
    ja: '太陽光架台',
  },
  屋頂型: {
    zh: '屋頂型',
    en: 'Roof Type',
    ja: '屋根設置型',
  },
  棚架型: {
    zh: '棚架型',
    en: 'Shed Type',
    ja: '駐車場設置型',
  },
  地面型: {
    zh: '地面型',
    en: 'Ground Type',
    ja: '地上設置型（野立て）',
  },
  電力相關產品: {
    zh: '電力相關產品',
    en: 'Power Related Products',
    ja: '電力関連製品',
  },
  電力相關: {
    // Define in CMS, but render differently in pages
    zh: '電力相關產品',
    en: 'Power Related Products',
    ja: '電力関連製品',
  },
  特殊型支架: {
    zh: '特殊型支架',
    en: 'Special Type Bracket',
    ja: '特殊型の架台',
  },
  特殊型: {
    // Define in CMS, but render differently in pages
    zh: '特殊型支架',
    en: 'Special Type Bracket',
    ja: '特殊型の架台',
  },
  板金工藝設計: {
    zh: '板金工藝設計',
    en: 'Metalwork and Design',
    ja: '板金の工芸設計',
  },
  成功案例: {
    zh: '成功案例',
    en: 'Success Cases',
    ja: '成功実績',
  },

  // Body
  更多新聞: {
    zh: '更多新聞與連結',
    en: 'More News & Links',
    ja: 'その他のニュースとリンク',
  },
  閱讀全文: {
    zh: '閱讀全文',
    en: 'Learn More',
    ja: '詳しくはこちら',
  },
  案場應用方案: {
    zh: '案場應用方案',
    en: 'Case Application',
    ja: '場合応用解決',
  },
  聯絡我們: {
    zh: '聯絡我們',
    en: 'Contact',
    ja: 'お問い合わせ',
  },
  產品技術諮詢: {
    zh: '產品、技術諮詢',
    en: 'Product and technical consultation',
    ja: '製品、技術相談',
  },
  聯絡表單內文: {
    zh: '請留下您的聯絡資訊，後續將由專人為您服務。',
    en: 'Please leave your contact information, and someone will contact you as soon as possible.',
    ja: 'あなたの連絡先情報を残してください、私たちは誰かにあなたに仕えるように頼みます。',
  },

  // Footer
  關於我們: {
    zh: '關於我們',
    en: 'About Jing Xin',
    ja: '会社案内',
  },
  公司簡介: {
    zh: '公司簡介',
    en: 'Company Profile',
    ja: '会社概要',
  },
  經營理念: {
    zh: '經營理念',
    en: 'Concept',
    ja: '経営理念',
  },
  產品特色: {
    zh: '產品特色',
    en: 'Product Characteristics',
    ja: '製品の特徴',
  },
  嚴選材料: {
    zh: '嚴選材料',
    en: 'Strictly Selected Materials',
    ja: '厳選された素材',
  },
  生產設備: {
    zh: '生產設備',
    en: 'Production Equipment',
    ja: '生産設備',
  },
  品質與認證: {
    zh: '品質與認證',
    en: 'Quality and Certification',
    ja: '品質と特許',
  },
  產品QA: {
    zh: '產品 Q&A',
    en: 'Product Q&A',
    ja: '製品 Q&A',
  },
  景欣股份有限公司: {
    zh: '景欣股份有限公司',
    en: 'JING-XIN LTD.',
    ja: 'チンシン株式会社',
  },
  使用條款: {
    zh: '使用條款',
    en: 'Privacy Policy',
    ja: '個人情報保護方針',
  },
  使用條款後綴: {
    zh: '© 2020 景欣股份有限公司 All Rights Reserved.',
    en: '© 2020 JING-XIN LTD., All Rights Reserved.',
    ja: '© 2020 チンシン株式会社、無断複写・転載を禁じます。',
  },
  地址: {
    zh: '地址',
    en: 'Address',
    ja: '住所',
  },
  電話: {
    zh: '電話',
    en: 'Phone',
    ja: '電話',
  },
  傳真: {
    zh: '傳真',
    en: 'Fax',
    ja: 'ファックス',
  },
  聯絡信箱: {
    zh: '聯絡信箱',
    en: 'E-mail',
    ja: 'E-mail',
  },
  // Homepage ^^^^^^^^^^

  // About 關於我們 **********
  首頁: {
    zh: '首頁',
    en: 'Home',
    ja: 'ホーム',
  },

  // 公司簡介
  公司簡介副標題: {
    zh: '太陽能支架的最佳夥伴',
    en: 'The best partner for solar mounting',
    ja: '我々はお客様の最高のパートナーとなります',
  },
  公司簡介內文: {
    zh: (
      <>
        <strong>景欣有限公司成立於2016年，於2019年更名為景欣股份有限公司</strong>
        ，景欣自成立以來致力於發展太陽能設施支撐架，藉由專業的研發團隊，我們取得了多項世界專利認證。
        <br /> <br />
        我們以「高調整性打造客製化服務」、「簡化的安裝程序」、「降低可控成本提高產品CP質」滿足不同客人的需求，持續精進產品功能及品質，給予客戶最好的服務以及品質保證。
        <br /> <br />
        半自動化工廠，維持產品穩定的品質與輸出，我們特別選用通過多項品質認證的世界級素材「鎂鋁鋅材料」使我們在太陽能產業的領域中獨樹一幟。
      </>
    ),
    en: (
      <>
        <strong>Jing-Xin. was established in 2016 and changed its name to Jing-Xin Ltd. in 2019. </strong>
        Since its establishment, Jingxin has been committed to the development of solar facility support frames. With a
        professional R{'&'}D team, we have obtained a number of world patent certifications.
        <br /> <br />
        We use "highly adjustable to create customized services", "simplified installation procedures", "reduced
        controllable costs and improve product CP quality" to meet the needs of different customers, continue to improve
        product functions and quality, provide customers with the best service and Quality Assurance.
        <br /> <br />
        In addition to focusing on optimizing the design of solar stents, we also exchange opinions with upstream and
        downstream simultaneously, make fundamental improvements from the component side to obtain effective progress,
        and gather ideas and experiences. At the same time, we actively apply for patents at home and abroad to
        accumulate research and development energy, ensure the quality of results, and make our profession more
        convincing.
      </>
    ),
    ja: (
      <>
        チンシン( 景欣)
        は設立以来、太陽エネルギー施設のサポートフレームの研究開発に取り組んでおり、専門の開発チームを通じて多国籍特許の認証を取得しています。
        <br /> <br />
        我々はそれぞれの顧客ニーズを満足させつつ、継続的に製品機能、品質向上及びカスタム化サービスを提供し、顧客のため尽力して喜んでいただけることが最優先です。
        <br /> <br />
        太陽光発電システムの設計の最適化に焦点を当てるだけでなく、我々も上流も下流もよく話し合い、パーツをはじめ根本的な改善に取り組み、さらに架台の性能を向上させます。そして、弊社の技術はさらに説得力になるため、アイデアと経験の積み重ねるにより、国内外で積極的に特許を申請して、研究開発エネルギーを蓄積し、製品の良品質を確保します。
      </>
    ),
  },
  半自動化工廠: {
    zh: '半自動化工廠',
    en: 'Semi-automated factory',
    ja: '半自動化工場',
  },
  半自動化工廠內文: {
    zh: '穩定輸出品質，顧客使用安心滿意',
    en: 'Stable output quality, customer satisfaction',
    ja: '安定した品質で、顧客は安心かつ満足して使用できます',
  },
  世界級素材: {
    zh: '世界級素材',
    en: 'World-class material',
    ja: '世界級の素材',
  },
  世界級素材內文: {
    zh: '特選高張力鎂鋁鋅鋼板，擁有多項認證',
    en: 'Specially selected high-tensile magnesium-aluminum-zinc steel sheet with multiple certifications',
    ja: '特選された高張力の溶融亜鉛、マグネシウム、アルミニウム鋼板は多くの認証を通過しました。',
  },
  客製化服務: {
    zh: '客製化服務',
    en: 'Customized service',
    ja: 'カスタム化サービス',
  },
  客製化服務內文: {
    zh: '團隊用心，需求最明瞭，服務最貼心',
    en: 'Attentive team, Clearest requirements, Most intimate serviceand',
    ja: 'チンシンは心をこめて最も明確にニーズに応じ、最高なサービスを提供します',
  },
  景欣企業三大服務特點: {
    zh: '景欣企業 - 三大服務特點',
    en: 'Jing-Xin LTD. - Three Service Features',
    ja: 'チンシン( 景欣) - 3 つの主要なサービスのポイント',
  },
  景欣企業三大服務特點1: {
    zh: '高調整性打造客製化服務',
    en: 'Highly adjustable to create customized services',
    ja: '高度に調整可能な構築カスタマイズ化サービス',
  },
  景欣企業三大服務特點2: {
    zh: '簡化的安裝程序',
    en: 'Simplified installation procedure',
    ja: '簡単な設置手順',
  },
  景欣企業三大服務特點3: {
    zh: '降低可控成本提高產品CP質',
    en: 'Reduce controllable costs and improve product CP quality',
    ja: 'コスト低減、製品のコスパを向上',
  },
  景欣企業服務特點總結: {
    zh: (
      <>
        <strong>專業設計能力＋嚴謹服務態度＝領先業界</strong>
        {' | '}
        <strong>我們專業謹慎，讓您安心使用</strong>
      </>
    ),
    en: (
      <>
        <strong>Professional design ability + Rigorous service attitude = Leading the industry</strong>
        {' | '}
        <strong>With our professional and cautious, you could use our product without any worry. </strong>
      </>
    ),
    ja: (
      <>
        <strong>専門の設計能力 + 厳格なサービス態度 = 業界をリードしています</strong>
        {' | '}
        <strong>専門の意識を持ち、且つ丁寧なサービス安心の提供</strong>
      </>
    ),
  },

  // 經營理念
  經營理念副標題: {
    zh: '一起為地球永續節能努力',
    en: 'Work together for sustainable energy conservation on the earth',
    ja: '地球の未来を支えるエネルギーの節約に貢献しています',
  },
  永續發展: {
    zh: '永續發展',
    en: 'Sustainable development',
    ja: '環境は永遠に',
  },
  永續發展內文: {
    zh:
      '溫室效應造成的氣候變遷及環境生態劇變，已經真實的發生在我們周遭。全球暖化除了造成大氣溫度上升外，也讓氣候產生了極端變化，逐漸影響我們的生活。為了生命的永續發展，如何讓環境減緩惡化甚至能逐步恢復，是刻不容緩的問題，也是我們努力的動力及目標。',
    en:
      'The climate, environmental and ecological changes caused by the greenhouse effect have actually happened around us. In addition to increasing atmospheric temperature, global warming also caused extreme changes in the climate, which gradually affected our lives. For the sustainable development of life, how to slow down the deterioration of the environment and even gradually restore it is an urgent issue and also the motivation and goal of our efforts.',
    ja:
      '温室効果による気候変動と生態環境の激変はまさに私たちの周辺で発生しています。地球温暖化は温度上昇以外にも、気候の極端な変化をもたらし、私たちの生活に影響を及ぼしています。生命の永続的な発展のために、いかにして環境の悪化を低減して回復させるかということが緊急の課題であり、私たちが努力すべき行動であり、目標でもあります。',
  },
  創新科技: {
    zh: '創新科技',
    en: 'Innovation and Technology',
    ja: '革新的な技術',
  },
  創新科技內文: {
    zh:
      '除了專注於優化太陽能發電系統的設計，我們也同步與上下游交流意見，從元件端就開始做根本性的改善，系統上才能獲得更有效果的進步。 彙整出想法與經驗，我們積極在海內外申請專利，來累積研發能量，既保障成果品質，也讓我們的專業更有信服力而非口說無憑。',
    en:
      'In addition to focusing on optimizing the design of the solar power generation system, we also simultaneously exchange opinions with upstream and downstream, and make fundamental improvements from the component side to achieve more effective progress in the system. Collecting ideas and experiences, we actively apply for patents at home and abroad to accumulate research and development energy, which not only guarantees the quality of the results, but also makes our profession more convincing rather than unfounded.',
    ja:
      '太陽光発電システムの最適化設計を専門とする以外に、私たちは上流や下流との意見交換を行い、ユニットから開始して根本的な改善を行い、システムでは要約さらに優れた効果を得ることができます。方法と経験をまとめ、私たちは積極的に国内外に特許申請を行い、研究開発のエネルギーを蓄積し、成果物の品質を保証し、私たちの専門をさらに信頼性のあるものとします。私たちは有言実行です。',
  },

  // 產品特色
  產品特色副標題: {
    zh: '掌握設計，提供最穩定的品質',
    en: 'Know the design well and provide the most stable quality',
    ja: '管理された設計、最も安定した品質で提供します',
  },

  // 嚴選材料
  嚴選材料副標題: {
    zh: '耐候耐力材料，克服環境考驗',
    en: 'Weather-resistant materials that overcome environmental challenges',
    ja: '環境問題を克服する耐候性材料',
  },
  支架選材: {
    zh: '支架選材',
    en: 'Bracket Selection',
    ja: '架台素材',
  },
  支架選材內文: {
    zh: (
      <>
        <ul>
          <li>
            <h5 className="m-0" style={{ color: 'rgba(74,74,74)' }}>
              <strong>高張力鎂鋁鋅鋼板</strong>
            </h5>
            <p>鎂鋁鋅鋼板特性在於抗鹽害耐腐蝕，具自我修復性，耐強鹼抗氨。</p>
          </li>
          <li>
            <h5 className="m-0" style={{ color: 'rgba(74,74,74)' }}>
              <strong>新型高耐腐蝕性鍍膜板</strong>
            </h5>
            <p>鍍膜層成分以鋅為主，由鎂鋁鋅鋼板以及微量的元素所製成，並符合國際級工業規格之認證。</p>
          </li>
        </ul>
      </>
    ),
    en: (
      <>
        <ul>
          <li>
            <h5 className="m-0" style={{ color: 'rgba(74,74,74)' }}>
              <strong>High-tension magnesium aluminum zinc coated plate</strong>
            </h5>
            <p>
              Characteristics of high tension magnesium aluminum zinc steel plates, resists salt corrosion,
              self-repairing, and resistant to alkali and ammonia.
            </p>
          </li>
          <li>
            <h5 className="m-0" style={{ color: 'rgba(74,74,74)' }}>
              <strong>New high corrosion resistance coated plate</strong>
            </h5>
            <p>
              The coating is primarily composed of zinc, produced from magnesium aluminum zinc steel plates and trace
              elements. It is certified to conform to international industrial specifications.
            </p>
          </li>
        </ul>
      </>
    ),
    ja: (
      <>
        <ul>
          <li>
            <h5 className="m-0" style={{ color: 'rgba(74,74,74)' }}>
              <strong>高張力溶融亜鉛･アルミニウム･マグネシウム鋼板</strong>
            </h5>
            <p>防食、耐食性、自己修復、耐強高アンモニア損傷。</p>
          </li>
          <li>
            <h5 className="m-0" style={{ color: 'rgba(74,74,74)' }}>
              <strong>新型高耐食性コーティングプレート</strong>
            </h5>
            <p>
              コーティング組成は主に亜鉛です、マグネシウムアルミニウム亜鉛鋼板と微量元素で作られており、国際的な工業認証に適合しています。
            </p>
          </li>
        </ul>
      </>
    ),
  },
  鋼材材料性質比較: {
    zh: '鋼材材料性質比較',
    en: 'Comparison of the steel material',
    ja: '機械的性質の比較',
  },
  材料種類: {
    zh: '材料種類',
    en: 'Material type ',
    ja: '材料種類',
  },
  密度: {
    zh: '密度',
    en: 'Density',
    ja: '密度',
  },
  抗拉強度: {
    zh: '抗拉強度',
    en: 'Tensile strength',
    ja: '引張強度',
  },
  抗拉強度定義: {
    zh: '破壞材料[發生破段現象]所需施加的外力數值',
    en: 'Damaged material<damages appeared> external force that must be added',
    ja: '材料が破損しない目安となる機械的特性が引張強度。',
  },
  降伏強度: {
    zh: '降伏強度',
    en: 'Yield strength',
    ja: '降伏強度',
  },
  降伏強度定義: {
    zh: '材料能忍受[最大施加外力而不發生永久變形]的數值',
    en: 'The maximum force that the material can endure < without permanent deformation>',
    ja: '塑性変形を起こさずに、材料に生じさせることのできる最大応力のこと。',
  },
  疲勞強度: {
    zh: '疲勞強度',
    en: 'Fatigue strength',
    ja: '疲労強度',
  },
  疲勞強度定義: {
    zh: '對材料施加一定頻率(106 次) 的外力負荷，[發生破斷相象]時的外力數值的數值',
    en: 'The number of times external force need to be applied on the material (106 times) to produce <breakage>',
    ja:
      '材料の疲労において、物体が振幅一定の繰返し応力を受けるとき、何回負荷を繰り返しても疲労破壊に至らない、またはそのように見なされる応力値のこと。',
  },
  伸長率: {
    zh: '伸長率',
    en: 'Extension',
    ja: '伸び率',
  },
  鋁: {
    zh: '鋁',
    en: 'Aluminum',
    ja: 'アルミニウム',
  },
  一般鋼板: {
    zh: '一般鋼板',
    en: 'Normal steel plate',
    ja: '冷間圧延鋼板',
  },
  鎂鋁鋅鋼板: {
    zh: '鎂鋁鋅鋼板',
    en: 'Magnesium aluminum zinc coated plate',
    ja: '亜鉛･アルミニウム･マグネシウム鋼板',
  },
  高張力鎂鋁鋅鋼板: {
    zh: '高張力鎂鋁鋅鋼板',
    en: 'High-tension magnesium aluminum zinc coated plate',
    ja: '高耐食性溶融めっき鋼板( 溶融亜鉛－アルミニウム－マグネシウム合金めっき鋼板)',
  },
  四百型: {
    zh: '400型',
    en: '400 type',
    ja: '400型',
  },
  高張力鎂鋁鋅鋼: {
    zh: '高張力鎂鋁鋅鋼',
    en: 'High tension magnesium aluminum zinc steel',
    ja: '高耐食性溶融めっき鋼板( 溶融亜鉛－アルミニウム－マグネシウム合金めっき鋼板)',
  },
  四百九型: {
    zh: '490型',
    en: '490 type',
    ja: '490型',
  },
  下載各材質實測報告: {
    zh: '各材料實測報告，請與我們連繫!',
    en: 'Materials are inspected by notary inspection agency',
    ja: '材料は公証人によって検証されます',
  },
  螺絲: {
    zh: '螺絲',
    en: 'Screw',
    ja: 'ボルト',
  },
  螺絲內文: {
    zh: (
      <>
        <h5 style={{ color: 'rgba(74,74,74)' }}>
          <strong>TS防鬆脫緊固件＝TS緊固＋緊固全球</strong>
        </h5>
        <ol>
          <li>日本國土交通省NETIS 認證</li>
          <li>美國航太規範NAS3350 振動測試通過</li>
          <li>通過日本JQA、台灣SGS、大陸CAM、歐洲容克式振動測試</li>
          <li>ISO9001：2015 品質認證</li>
          <li>17 級風風洞測試通過( 景欣支架+TS 德剛防鬆脫扣件)</li>
          <li>取得30 餘國的專利保護、台灣製造自創TS 品牌</li>
        </ol>
      </>
    ),
    en: (
      <>
        <h5 style={{ color: 'rgba(74,74,74)' }}>
          <strong>TS locking securing piece = TS secure + secure across the globe</strong>
        </h5>
        <ol>
          <li>Japan Ministry of Land, Infrastructure, Transport and Tourism NETIS certification</li>
          <li>Passed the American NAS3350 aerospace vibration test certification</li>
          <li>Passed the Japanese JQA, Taiwanese SGS, China CAM, and European junker vibration tests</li>
          <li>ISO 9001 : 2015 quality certification</li>
          <li>Passed the category 17 wind tunnel test (Jing Xin support +TS Tech-Stell locking fastener)</li>
          <li>Received patent protection from over 30 countries, Taiwan’s own TS brand</li>
        </ol>
      </>
    ),
    ja: (
      <>
        <h5 style={{ color: 'rgba(74,74,74)' }}>
          <strong>TS 徳剛緩め止めねじ＝ TS ファスナー＋ファスナーグローバル</strong>
        </h5>
        <ol>
          <li>日本国土交通省NETIS 認証</li>
          <li>米国航空規格NAS3350 振動測試通過</li>
          <li>日本JQA、台湾SGS、中国大陸CAM、欧州ユンカー振動試験に合格</li>
          <li>ISO9001：2015 品質認証</li>
          <li>17級風洞試験に合格( チンシン( 景欣) 架台+ TS 德剛緩み止めファスナー)</li>
          <li>30 か国以上で特許保護を取得し、台湾でTS ブランドを作りました</li>
        </ol>
      </>
    ),
  },
  注意事項: {
    zh: '鎖附時的注意事項',
    en: 'Precaution',
    ja: '注意事項',
  },
  注意事項內文: {
    zh: (
      <>
        <ol>
          <li>螺帽與華司的楔型面要兩兩相互對正</li>
          <li>螺帽先用手轉入3~5 牙，再使用電動板手或鎖緊工具進行鎖附</li>
          <li>正確選用套筒尺寸，M8 使用13mm 對邊的套筒，M10 使用17mm 的套筒，M12 使用19mm</li>
          <li>鎖附時套筒要對正螺帽並確認完全套入後再啟動旋轉鎖緊的動作</li>
          <li>鎖附時要使用低轉速(2500 轉) 避免鍍層刮損、請勿使用衝擊式板手</li>
        </ol>
      </>
    ),
    en: (
      <>
        <h5 style={{ color: 'rgba(74,74,74)' }}>
          <strong>stainless steel securing piece, precautions when locking</strong>
        </h5>
        <ol>
          <li>The nut and the washer’s wedge surface must be mutually aligned</li>
          <li>
            The nut must first be manually screwed in for 3~5 threads before using an electric wrench or locking tool to
            tighten and lock the nut
          </li>
          <li>
            Select the correct size sleeve. M8 uses a 13mm sleeve, M10 uses a 17mm sleeve, and M12 uses a 19mm sleeve
          </li>
          <li>
            When locking, the sleeve must be matched to the nut and the turning and tightening action can only be done
            when the sleeve is completely fitted to the nut
          </li>
          <li>
            When locking, use a low rotation speed (2500 rpm) to avoid scratching the plating. Do not use an impact type
            wrench
          </li>
        </ol>
      </>
    ),
    ja: (
      <>
        <h5 style={{ color: 'rgba(74,74,74)' }}>
          <strong>ロック時ステンレスの材質のファスナー</strong>
        </h5>
        <ol>
          <li>ナットとワッシャーのくさび面を互いに揃える必要があります</li>
          <li>まずナットを手で3 〜 5 本の歯に回してから、電動レンチまたはロックツールを使用してロッ クします</li>
          <li>
            正しいスリーブサイズを選択し、M8 は13mm の反対側のスリーブを使用し、M10 は17mm のスリーブを使用し、M12
            は19mm を使用します。 イギリススリーブや不適切なサイズのス
          </li>
          <li>
            スリーブをロックするときは、ナットを位置合わせして、完全に挿入されていることを確認し
            てから回転ロック動作を開始する必要があります
          </li>
          <li>ロックの時、インパクトレンチ低速（2500 rpm）を使用します。トルクレンチを使用してない でください</li>
        </ol>
      </>
    ),
  },
  設計: {
    zh: '設計',
    en: 'Design',
    ja: '設計',
  },
  設計內文: {
    zh:
      '採用德鋼防鬆脫螺絲料件，德剛公司利用華司上的六個楔型面旋轉產生反向機械力(橫向力轉為縱向力)，而華司的另一個作用面上的12 條線則會緊咬住連結物表面，輕鬆達到穩定的扭力及防鬆效果。',
    en:
      'Use Tech-Stell locking screws. Tech-Stell Co., Ltd., uses the rotation of the six wedge surfaces above the washer to produce reverse mechanical force (converting transverse force to longitudinal force). The 12 lines on the washer’s other action surface will tightly bite into the surface of the connected item to achieve stable torque and a locking effect.',
    ja:
      '「德剛」がワッシャーの六の楔面を利用し、ワッシャーを廻してから正反対の機械力を生じ( 横の力が縦の力に変わる) 其の同時にワッシャーの反対側12 本の爪が締結物をしっかり齧り込んで簡単に安定なトルクと緩み止めの効果を得ています。',
  },
  材質: {
    zh: '材質',
    en: 'Material',
    ja: '材質',
  },
  材質內文: {
    zh: (
      <>
        白鐵SUS304，強度為ISO7等級附上絕緣墊片預防接觸面的異金屬電位差，為我司發明專利項目之一
        <br />
        中碳鋼，強度為ISO8.8等級外觀做防鏽鋅錫合金鍍層處理
      </>
    ),
    en:
      'Medium-carbon steel with a strength of ISO 8.8 and exterior rust prevention processing (zinc and tin alloy) processing.',
    ja: '中炭素鋼、強度はISO 8.8 等級、外観は防錆（亜鉛- スズ合金）です。',
  },
  JQA試驗成績書: {
    zh: 'JQA 試驗成績書',
    en: 'JQA test performance report',
    ja: 'JQA 試験成績書',
  },
  專利: {
    zh: '景欣發明專利',
    en: 'Patent',
    ja: '特許',
  },
  專利內文: {
    zh: '不銹鋼、強度為ISO 7 等級附上絕緣墊片預防接觸面的異金屬電位差，為我司發明專利項目之一。',
    en:
      'Stainless steel SUS304 with a strength of ISO level 7 comes with an insulated spacer to prevent the potential difference of the different metal contact surfaces , which is one of the invention patents of this company.',
    ja: '白鉄SUS304、強度はISO 7 等級、絶縁ガスケットは接触面の異なる金属間の電位差を防ぐために取り付けられています。',
  },
  一般鎖固: {
    zh: '一般鎖固',
    en: 'General locking',
    ja: '一般取付方法',
  },
  隔絕鎖固: {
    zh: '隔絕鎖固',
    en: 'Insulated locking',
    ja: '電位差を防ぐ取付方法',
  },

  // 生產設備
  生產設備副標題: {
    zh: '我們擁有各種最先進的設備，可以滿足客戶的各種需求',
    en: 'We have a variety of the most advanced equipment to meet the various needs of customers.',
    ja: 'お客様のさまざまなニーズに対応するために、さまざまな最先端の機器を取り揃えています',
  },

  // 品質與認證
  ...CERTEIFICATE,
  品質與認證副標題: {
    zh: '完善的品質管制程序、創新多項專利認證',
    en: 'Complete quality control procedures, innovative multiple patent certifications',
    ja: '標準化された品質管理手順、革新的かつ複数の特許の認証',
  },
  品質保證: {
    zh: '品質保證',
    en: 'Quality Assurance',
    ja: '品質保証',
  },
  客製化接案流程: {
    zh: '客製化接案流程',
    en: 'Customized case procedure',
    ja: 'カスタマイズプロセス',
  },
  品管流程: {
    zh: '品管流程',
    en: 'Quality control process',
    ja: '品質管理プロセス',
  },
  下載實驗報告與認證: {
    zh: '實驗報告與認證，請與我們連繫!',
    en: 'Material test report',
    ja: '材料試験報告書',
  },
  專利認證: {
    zh: '專利認證',
    en: 'Patent certificate',
    ja: '特許証書',
  },
  台灣專利: {
    zh: '台灣專利',
    en: 'Taiwan patents',
    ja: '台湾 特許',
  },
  日本專利: {
    zh: '日本專利',
    en: 'Japanese patents',
    ja: '日本 特許',
  },
  德國專利: {
    zh: '德國專利',
    en: 'German patents',
    ja: 'ドイツ 特許',
  },
  美國專利: {
    zh: '美國專利',
    en: 'American patent',
    ja: '米国 特許',
  },

  // 板金工藝設計
  板金工藝設計副標題: {
    zh: '成熟的『板、金』工藝基礎，創造日常中的隱藏商機',
    en: 'The mature "board, metal" craft foundation creates hidden business opportunities in life.',
    ja: '成熟した「板金」工芸技術の基礎から生活に隠されたビジネスチャンスを生み出します',
  },
  合作洽詢: {
    zh: '合作洽詢',
    en: 'Cooperation inquiry',
    ja: 'ビジネスの連絡先',
  },
  // About 關於我們 ^^^^^^^^^^

  // Product 產品 **********
  太陽能支架應用方案: {
    zh: '太陽能支架應用方案',
    en: 'Mounting Bracket Solution',
    ja: '太陽光架台応用解決',
  },
  了解產品: {
    zh: '了解產品',
    en: 'More Products',
    ja: 'もっとの製品',
  },
  同類型支架方案: {
    zh: '同類型支架方案',
    en: 'Related products',
    ja: '関連製品',
  },
  All: {
    zh: 'All',
    en: 'All',
    ja: 'All',
  },
  EZ安裝: {
    zh: 'EZ 安裝',
    en: 'EZ Installation',
    ja: '简易安装',
  },
  專利證書: {
    zh: '專利證書',
    en: 'Patent Certification',
    ja: '特許証明書',
  },
  專利證書內文: {
    zh: '各部件專利申請中，部分已取得台灣、日本、德國三國發明及新型專利。',
    en:
      'Among the component patent applications, some of them have obtained invention and new model patents in Taiwan, Japan, and Germany.',
    ja: '各部品の特許の出願中には台湾、日本とドイツで発明および新モデルの特許を取得しているものもあります。',
  },
  產品技術諮詢內文: {
    zh: '告訴我們您對於太陽能佈建的想法，我們將讓它變得更好。',
    en: 'Please tell us your thoughts on solar installation and we will make it better.',
    ja: 'ソーラー設置についてのご意見をお聞かせください。改善させていただきます。',
  },
  // Product 產品 ^^^^^^^^^^

  // Successful Case 成功案例 **********
  案場完工日期: {
    zh: '案場完工日期',
    en: 'Completion date of the case',
    ja: '案件完成日',
  },
  支架使用類型: {
    zh: '支架使用類型',
    en: 'Type of backet used',
    ja: '架台使用タイプ',
  },
  成功案例內文: {
    zh: (
      <>
        <p>
          最新完成案例，請參訪 <a href="https://www.facebook.com/jingxinco">FaceBook 粉絲專頁</a> !
        </p>
        <p>
          "景欣"專注於"鎂鋁鋅"粉體烤漆支架研發與生產，針對全球各種太陽能集電案場，甚至極端嚴苛的環境條件，我們運用材料特性與設計經驗，達到客人的要求，完工案場分享～
          <br />
          提供穩定的企業合作，歡迎選用景欣。
        </p>
      </>
    ),
    en: (
      <>
        <p>
          The recently cases, please refer to our <a href="https://www.facebook.com/jingxinco">Facebook fan page</a> !
        </p>
        <p>
          "Jingxin" focuses on the {'R&D'} and production of "magnesium-aluminum-zinc" brackets. We use material
          characteristics and design experience to meet the requirements of our customers in response to the needs of
          various solar installations around the world.
          <br />
          Provide stable business cooperation, welcome to choose Jingxin.
        </p>
      </>
    ),
    ja: (
      <>
        <p>まずはご来店ありがとうございました。</p>
        <p>
          「Jingxin」は、「マグネシウム-アルミニウム-亜鉛」ステントの研究開発と製造に重点を置いており、世界中のさまざまな太陽光発電設備のニーズに応えて、お客様の要件を満たすために材料特性と設計経験を使用しています。
          <br />
          安定したビジネス協力を提供し、Jingxinを選択することを歓迎します。
        </p>
      </>
    ),
  },
  了解案例: {
    zh: '了解案例',
    en: 'Learn More',
    ja: '詳細なケース',
  },
  // Successful Case 成功案例 ^^^^^^^^^^

  // 新聞 News **********
  新聞中心: {
    zh: '新聞中心',
    en: 'More News & Links',
    ja: 'その他のニュースとリンク',
  },
  發布日期: {
    zh: '發布日期',
    en: 'Release date',
    ja: '発表日',
  },
  新聞來源: {
    zh: '新聞來源',
    en: 'News source',
    ja: 'ニュースソース',
  },
  新聞中心內文: {
    zh: '我們將不定期發布各項公司新訊，與國內外太陽能產業相關訊息；可依分類項目瀏覽。',
    en:
      'We will release various information related to the solar energy industry from time to time; please browse by category.',
    ja: 'ソーラーエネルギー業界に関する様々な情報を随時公開していきますので、カテゴリー別にご覧ください。',
  },
  公司快訊: {
    zh: '公司快訊',
    en: 'Company news',
    ja: 'ホットニュース',
  },
  特價商品: {
    zh: '特價商品',
    en: 'Special offer',
    ja: 'お買い得製品',
  },
  媒體報導: {
    zh: '媒體報導',
    en: 'Media reports',
    ja: 'メディアレポート',
  },
  產業新知: {
    zh: '產業新知',
    en: 'Industry news',
    ja: '業界ニュース',
  },
  // 新聞 News ^^^^^^^^^^

  // QA **********
  產品QA內文1: {
    zh:
      '眾多的太陽能支架，甚至更多的廠商，不同的支架百花齊放、琳瑯滿目的選擇。但您除了需要案場環境之外，我們歸納各類型常見問題供您參考。若有任何問題，',
    en:
      'With many solar mounts, we have summarized various common problems for your reference. If you have any questions, ',
    ja:
      '多くのソーラーマウントを使用して、参考のためにさまざまな一般的な問題を要約しました。ご不明な点がございましたら、',
  },
  產品QA內文2: {
    zh: '歡迎與我們聯絡',
    en: 'Welcome to contact us',
    ja: 'お問い合わせへようこそ',
  },
  銷售洽詢: {
    zh: '銷售洽詢',
    en: 'Sales inquiry',
    ja: '営業のお問い合わせ',
  },
  出貨相關: {
    zh: '出貨相關',
    en: 'Shipment related',
    ja: '出荷関連',
  },
  太陽能支架應用: {
    zh: '太陽能支架應用',
    en: 'Solar mounting application',
    ja: '太陽光架台運用',
  },
  分類方式: {
    zh: '分類方式',
    en: 'Filter Category',
    ja: '分類方法',
  },
  // QA ^^^^^^^^^^

  // Search 搜尋 **********
  搜尋結果字串1: {
    zh: '您搜尋「',
    en: 'You searched the relevant pages in the "',
    ja: '「',
  },
  搜尋結果字串2: {
    zh: '」站內相關頁面如下：',
    en: '" site as follows:',
    ja: '」のウェブサイトで関連ページを検索した結果は次のとおりです。',
  },
  未找到相符的內容: {
    zh: '未找到相符的內容',
    en: 'No match found',
    ja: '一致するものが見つかりません',
  },
  // Search 搜尋 ^^^^^^^^^^
};

export const getPackOf = (langCode) => {
  const langPackWithCode = {};
  Object.keys(langPack).forEach((key) => {
    langPackWithCode[key] = langPack[key][langCode];
  });
  return langPackWithCode;
};
